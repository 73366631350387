import styled from "styled-components"

export const SmallMaxWidth = styled.section`
    max-width: 37rem;
    margin: auto;
    z-index: 3;
    position: relative;
    padding: 0rem 1rem;

    @media (max-width: 768px) {
        padding: 0 2rem;
    }
`

export const MedMaxWidth = styled.section`
    max-width: 52rem;
    margin: auto;
    z-index: 3;
    padding: 0rem 1rem;

    position: relative;

    @media (max-width: 768px) {
        padding: 0 2rem;
    }
`

export const LargeMaxWidth = styled.section`
    max-width: 60rem;
    margin: auto;
    z-index: 3;
    position: relative;
    padding: 0rem 1rem;

`

export const MainContentMaxWidth = styled.section`
    max-width: 89rem;
    padding: 2rem;
    margin: 2rem auto;
    z-index: 3;
    position: relative;

    @media (max-width: 768px) {
        padding:1.5rem;
        padding-top: 0;
    }
    
`