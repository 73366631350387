import React, { PureComponent } from 'react'
import styled from 'styled-components'

import { P } from '../Typography'
import Image from '../Image'

const Block = styled.section`
  h2 {padding-left: 1rem}
  text-align: center;
  padding: 0.5rem;

  @media (max-width: 800px) {
    text-align: left;
  }


`
const GameplayImage = styled.div`
  width: 100%;
`

// const TextBlock = styled.div`
//   padding: 1rem;
// `

export default class GameplayBlock extends PureComponent {
  render() {
    const { image, text } = this.props

    return (
      <Block>
        <GameplayImage>
          <Image src={image} />
          <P>{text}</P>
        </GameplayImage>
      </Block>
    )
  }
}
