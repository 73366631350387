import React from "react"
import styled from "styled-components"

const OutterButton = styled.div`
	transition: 0.3s ease-in-out filter;
	clip-path: polygon(0% 0%, calc(100% - 10px) 0%, 100% 10px, 100% 100%, 10px 100%, 0% calc(100% - 10px));
	font-family: var(--condensedFont);
	font-size: 1.5rem;
	font-weight: 500;
	line-height: 1;
	text-transform: uppercase;
	color: white;
	background: #00D7FD;
	display:inline-block;
	width: 100%;
	position: relative;
	padding: 1.25rem;
`

const Link = styled.a`
	position: relative;	
	display:inline-block;
	filter:drop-shadow(0px 0px 25px rgba(0, 215, 253, 0.25));
	max-width: 14rem;
	width: 100%;
	margin: auto;

	&:hover {
		.inner-button {
			background: linear-gradient(180deg, #29BFFF 46.09%, #069FE1 46.78%);
			background: linear-gradient(#29BFFF 0%, #29BFFF 29%, #29BFFF 49%, #069FE1 50%, #069FE1 100%);
		}
	}
`

const Button = styled.div`
	z-index: 4;
	position: relative;
`

const InnerButton = styled.div`
	position: absolute;
	top: 1px;left:1px;
	width: calc(100% - 2px);
	height: calc(100% - 2px);

	clip-path: polygon(0% 0%, calc(100% - 10px) 0%, 100% 10px, 100% 100%, 10px 100%, 0% calc(100% - 10px));
	background: linear-gradient(#0AB5FF 0%, #0AB5FF 29%, #0AB5FF 49%, #0798D6 50%, #0798D6 100%);
	pointer-events: none;
`


const PlayNowButton = ({
	color,
	label = "Play Now"
}) => {
	return (
		<Link href="https://play.skyweaver.net" target="_blank" rel="noopener noreferrer">
			<OutterButton>
				<Button>
					{label}
				</Button>
				<InnerButton className="inner-button" />
			</OutterButton>
		</Link>

	)
}

export default PlayNowButton