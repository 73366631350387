import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import Slider from "react-slick";

import GameplayBlock from './GameplayBlock'
import { Button, H2 } from '../Typography'
import { RightArrow, LeftArrow } from './GameplayArrows'

const Slideshow = styled.div`
  width: 45%;
  max-width: 40rem;
  position: absolute;
  right: 0px;
  margin-right: 10rem;
  z-index: 1;
  top:50%;

  @media (min-width: 1600px) {
    max-width: 50rem;
  }

  @media (max-width: 800px) {
    width: 48%;
  }

  h2, p {padding-left: 1rem; width: 100%;}

  transform: translateX(-4rem) translateY(-50%);

  svg {
    width: 100%;
    fill: var(--white);
    cursor: pointer;
  }

  .slick-slide {
    &, h2, figure, .text-block {
      opacity: 0;
      transition: 0.5s ease-out opacity, 0.3s ease-out transform;
      will-change: transform;
    }

    h2, figure, .text-block {
      transform: translateY(1rem);
    }
  }

  .slick-slide.slick-active {
    &, h2, figure, .text-block {
      opacity: 1;
      transform: translateY(0);
    }

    h2 {transition-delay: 0s; color: var(--white)}
    figure {transition-delay: 0s;}
    .text-block {transition-delay: 0.4s;}
  }

  .gameplay__slideshowheader--focused {

      display: none;
    
  }

  img {padding: 1rem;}

  @media (max-width: 1200px) {
    padding: 2rem;
    width: 60%;
    max-width: unset;
    margin-top: 0rem;
    text-align: center;
    order: 2;
    margin-right: 0rem;
    margin-top: 2rem;
  }

  @media (max-width: 800px) {
    text-align: left;
    width: 100%;
    margin-top: -2rem;
    transform: none;
    position: relative;
  }

`

export const PrevArrow = styled.div`
  position: absolute;
  left: -3rem;
  width: 2rem;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s ease-out opacity;

  &:hover {opacity: 0.6}

  &.slick-disabled {opacity: 0.2}
  @media (max-width: 750px) {
    display: none;
  }
`
export const NextArrow = styled.div`
  position: absolute;
  right: -3rem;
  width: 2rem;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s ease-out opacity;

  &:hover {opacity: 0.6}

  &.slick-disabled {opacity: 0.2}
  @media (max-width: 750px) {
    display: none;
  }
`

// const HiddenEl = styled.div`
//   display: none;
//   pointer-events:none;
//   visibility: hidden;
// `

export const PrevArrEl = ({ className, to, onClick }) => {
  const onKeyPress = (e) => {
    if (e.keycode === 13) {
      onClick()
    }
  }
  return (
    <PrevArrow className={className}>
      <span onClick={onClick} onKeyDown={onKeyPress} aria-label="Previous Slide" role="button" tabIndex="-1"><LeftArrow /></span>
    </PrevArrow>
  )
}


export const NextArrEl = ({ className, to, onClick }) => {
  const onKeyPress = (e) => {
    if (e.keycode === 13) {
      onClick()
    }
  }
  return (
    <NextArrow className={className}>
      <span onClick={onClick} onKeyDown={onKeyPress} role="button" aria-label="Next Slide" tabIndex="0"><RightArrow /></span>
    </NextArrow>
  )
}

const TrackerEl = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const Dot = styled.button`
  height: 10px;
  width: 10px;
  margin: 0px 5px;
  border-radius: 50%;
  opacity: 0.3;
  background-color: #fff;
  transition: 0.4s ease-out opacity;

  ${(props) => {
    if (props.i === props.selectedIndex) return css`
      opacity: 1;
    `
  }}
`

export const Tracker = ({ elements, selectedIndex, goTo }) =>
  <TrackerEl>
    {elements.map((el, i) => <Dot onClick={() => goTo && goTo(i)} key={i} selectedIndex={selectedIndex} i={i} />)}
  </TrackerEl>

export default class GameplaySlideshow extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      selectedIndex: 0
    }
  }

  clickNext = () => {
    const { selectedIndex } = this.state
    const { gameplayFields } = this.props

    if (selectedIndex + 1 === gameplayFields.length) this.setState({ selectedIndex: 0 })
    else this.setState({ selectedIndex: this.state.selectedIndex + 1 })
  }

  clickPrev = () => {
    const { selectedIndex } = this.state
    const { gameplayFields } = this.props

    if (selectedIndex === 0) this.setState({ selectedIndex: gameplayFields.length - 1 })
    else this.setState({ selectedIndex: this.state.selectedIndex - 1 })
  }

  selectOption = (option) => {
    this.setState({ selectedIndex: option })
  }

  onSlideshowClick() {
    console.log('onslideshowclick')
    const { focused, setFocused } = this.props;
    if (!focused) {
      setFocused(!focused)
    }

  }

  render() {
    const { selectedIndex } = this.state
    const { gameplayFields, title, focused } = this.props
    const slideShowOptions = {
      nextArrow: <NextArrEl />,
      prevArrow: <PrevArrEl />,
      beforeChange: (current, next) => this.selectOption(next)
    }

    return (
      <Slideshow className='gameplay__slideshow' style={focused ? { width: '80vw', marginTop: '20px', zIndex: '999', maxWidth: '100%', position: 'fixed', background: '#0C061E', border: '1px solid black', top: '40px' } : {}}>
        <div>
          <div className={focused ? `gameplay__slideshowheader--focused` : ``}>
            <Button color="white" style={{ paddingLeft: '1rem' }}>GAMEPLAY</Button>
            <H2 uppercase color="white">{title}</H2>
          </div>
          {focused && (
            <button style={{ color: 'white', position: 'relative', top: '20px', fontSize: '20px', marginBottom: '10px', left: '20px', fontFamily: 'Barlow', cursor: 'pointer' }} onClick={() => { this.props.setFocused(false) }}>
              X Close
            </button>
          )}
          <div style={focused ? { background: '#0C061E', padding: '10px' } : {}}>
            <Slider {...slideShowOptions} >
              {gameplayFields.map((block, i) =>
                <GameplayBlock  {...block} key={`gameplay-block-${i}`} onClick={() => { this.onSlideshowClick() }} />
              )}
            </Slider>
            <Tracker elements={gameplayFields} selectedIndex={selectedIndex} />
          </div>
        </div>
      </Slideshow>
    )
  }
}